import { IStep } from '@/packages/ui/stepper/types'

export default [
  {
    id: 'recipient',
    title: 'Investors'
  },
  {
    id: 'terms',
    title: 'Terms'
  },
  {
    id: 'reviewAndSign',
    title: 'Review and Sign'
  }
] as IStep[]
