import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { NoPermissionsTooltip } from '@/features/dashboard'
import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import noteIcon from '@/features/dashboard/assets/images/note.png'
import noteMoneyIcon from '@/features/dashboard/assets/images/note-money.png'
import sentIcon from '@/features/dashboard/assets/images/summary/safes-sent.png'
import unpaindIcon from '@/features/dashboard/assets/images/summary/unpaid-safes.png'
import { useModalContext } from '@/features/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  Disabled,
  FontWeight,
  Row,
  Text,
  TextAlign,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './DashboardSummarySafe.module.scss'

interface DashboardSummarySafeProps {
  isEmpty?: boolean
  type: 'completed' | 'incomplete'
  count: number
  amount: number
  role: ProfileTypes
  onClick?: () => void
  withoutAction?: boolean
  disabled?: boolean
  permission: TeamMemberPermission
}

const DashboardSummarySafe: FC<DashboardSummarySafeProps> = ({
  type,
  count,
  disabled,
  isEmpty,
  withoutAction,
  amount,
  permission,
  role,
  onClick
}) => {
  const { dontHavePermissionModal } = useModalContext()

  const isEntrepreneur = role === ProfileTypes.ENTREPRENEUR
  const completed = type === 'completed'

  const allowAction = TeamMemberPermission.CREATE === permission

  const title = useMemo(() => {
    if (completed) {
      if (isEntrepreneur)
        return count === 1 ? 'SAFE completed' : 'SAFEs completed'

      return count === 1 ? 'SAFE' : 'SAFEs'
    }

    return count === 1 ? 'SAFE incomplete' : 'SAFEs incomplete'
  }, [completed, count, isEntrepreneur])

  const totalTitle = useMemo(() => {
    if (completed) {
      return isEntrepreneur ? 'Received funds' : 'Total amount'
    }

    return 'Pending funds'
  }, [completed, isEntrepreneur])

  const handleClick = () => {
    if (!allowAction) {
      dontHavePermissionModal.openModal()
      return
    }

    onClick?.()
  }

  const Total = (
    <Row items="center" justify="between" gap={5}>
      <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
        {totalTitle}:
      </Text>
      <Text
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.SEMIBOLD}
        color={
          // eslint-disable-next-line no-nested-ternary
          completed
            ? isEntrepreneur
              ? Color.success500
              : Color.primary500
            : Color.warning300
        }
      >
        {toCurrencyFormat(amount, '$')}
      </Text>
    </Row>
  )

  const Title = (
    <Text
      type={TextTypes.BODY_SMALL}
      color={Color.neutral400}
      className={styles.numberWrapper}
    >
      <span className={styles.number}>{count}</span>
      {title}
    </Text>
  )

  const Action = (
    <Disabled disabled={disabled}>
      <NoPermissionsTooltip permission={permission}>
        <Row
          gap={4}
          items="center"
          className="tw-cursor-pointer"
          onClick={handleClick}
        >
          {completed ? (
            <SharedIcons.PlusThin size={18} color={Color.primary500} />
          ) : (
            <SendReminder size={18} color={Color.primary500} />
          )}
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.SEMIBOLD}
            color={Color.primary500}
          >
            {completed ? 'Create SAFE' : 'Remind'}
          </Text>
        </Row>
      </NoPermissionsTooltip>
    </Disabled>
  )

  const IncompleteEmptyState = (
    <div className={clsx(styles.noData, isEntrepreneur && styles.entrepreneur)}>
      <Col items="center" gap={6}>
        <img src={noteMoneyIcon} alt="" height={42} />

        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          Track your pending SAFEs
        </Text>
      </Col>

      <ConditionalRender
        condition={isEntrepreneur}
        fallbackElement={
          <div className={styles.tooltip}>
            <Tooltip kind={TooltipKind.INFO} content="Lorem ipsum" />
          </div>
        }
      >
        <Text
          color={Color.primary100}
          className="!tw-p-0 tw-mt-[10px] tw-self-center"
        >
          No SAFEs yet
        </Text>
      </ConditionalRender>
    </div>
  )

  const CompletedEmptyState = (
    <div className={clsx(styles.noData, isEntrepreneur && styles.entrepreneur)}>
      <Col items="center" gap={6}>
        <img src={noteIcon} alt="" height={42} />

        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          {completed
            ? 'Create and send a SAFE'
            : 'You don’t have any SAFEs yet'}
        </Text>
      </Col>

      <ConditionalRender
        condition={isEntrepreneur}
        fallbackElement={
          <div className={styles.tooltip}>
            <Tooltip kind={TooltipKind.INFO} content="Lorem ipsum" />
          </div>
        }
      >
        <NoPermissionsTooltip
          className="tw-self-center"
          permission={permission}
        >
          <Button
            width="fit"
            appearance="ordinary"
            className="!tw-p-0 tw-mt-[10px]"
            onClick={onClick}
          >
            <SharedIcons.PlusThin size={23} className="tw-mr-2" />
            Create SAFE
          </Button>
        </NoPermissionsTooltip>
      </ConditionalRender>
    </div>
  )

  if (isEmpty) {
    return completed ? CompletedEmptyState : IncompleteEmptyState
  }

  if (isEntrepreneur) {
    return (
      <Row
        className={clsx(styles.safesBlock, styles.entrepreneurMode)}
        items="stretch"
        gap={16}
      >
        <Col className="tw-flex-1" justify="between" gap={8}>
          <Row justify="between" gap={20}>
            <img
              height={32}
              src={completed ? sentIcon : unpaindIcon}
              alt=""
              className={styles.icon}
            />

            {Action}
          </Row>

          <Col gap={8}>
            {Title}
            {Total}
          </Col>
        </Col>
      </Row>
    )
  }

  return (
    <Row
      className={clsx(styles.safesBlock, styles.angel)}
      items="stretch"
      gap={16}
    >
      <Col className="tw-flex-1" justify="between" gap={8}>
        <Row justify="between" gap={20}>
          <img
            width={32}
            src={completed ? sentIcon : unpaindIcon}
            alt=""
            className={styles.icon}
          />

          <ConditionalRender condition={!withoutAction}>
            <Text
              clickable
              type={TextTypes.BODY_DEFAULT}
              weight={FontWeight.MEDIUM}
              color={Color.primary500}
              onClick={onClick}
              className="tw-cursor-pointer"
            >
              View all
            </Text>
          </ConditionalRender>
        </Row>

        <Col gap={8}>
          {Title}
          {Total}
        </Col>
      </Col>
    </Row>
  )
}

export default DashboardSummarySafe
