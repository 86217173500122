import clsx from 'clsx'
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { StaticIcons } from '@/assets/icons'
import { ConditionalRender, UserProfilePopup } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { VerifyEmailMessage } from '@/features/dashboard'
import { Color } from '@/packages/palette'
import { FontWeight, Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './HeaderBar.module.scss'
import { Notifications } from './Notifications'

const mapStateToProps = ({ user }: StoreType) => ({
  user: user.me
})

interface IProps extends ReturnType<typeof mapStateToProps> {
  withExit?: boolean
  collapsedMenu?: boolean
  setCollapsedMenu?: (value: (prev: boolean) => boolean) => void
  onExit?: () => void
  rightSide?: JSX.Element
  middleComponent?: JSX.Element
}

const HeaderBar: FC<IProps> = (props) => {
  const {
    user,
    rightSide,
    withExit = false,
    middleComponent,
    collapsedMenu,
    onExit,
    setCollapsedMenu
  } = props
  const navigate = useNavigator()
  const location = useLocation()

  const toggleMenu = () => {
    setCollapsedMenu?.((prev: boolean) => !prev)
  }

  const BurgerMenu = (
    <Row items="center" gap={12} className="tw-ml-[-14px]">
      <ConditionalRender condition={location.pathname.includes('dashboard')}>
        <div
          onClick={toggleMenu}
          className={clsx(
            styles.burgerWrapper,
            !collapsedMenu && styles.active
          )}
        >
          <div className={styles.burgerMenu}>
            <span />
            <span />
            <span />
          </div>
        </div>
      </ConditionalRender>
      <StaticIcons.Logo
        className={styles.logo}
        onClick={() => navigate.toDashboard()}
      />
    </Row>
  )

  const LeftSide = (
    <ConditionalRender condition={withExit} fallbackElement={BurgerMenu}>
      <Text
        weight={FontWeight.SEMIBOLD}
        type={TextTypes.BODY_SMALL}
        color={Color.neutral300}
        onClick={onExit}
        clickable
      >
        Exit
      </Text>
    </ConditionalRender>
  )

  const Middle = (
    <ConditionalRender
      condition={!!middleComponent}
      fallbackElement={<VerifyEmailMessage />}
    >
      {middleComponent}
    </ConditionalRender>
  )

  const RightSide = (
    <ConditionalRender condition={!rightSide} fallbackElement={rightSide}>
      <Row gap={12} items="center">
        <Notifications />
        <UserProfilePopup user={user} />
      </Row>
    </ConditionalRender>
  )

  return (
    <header className={styles.header}>
      {LeftSide}

      {Middle}

      {RightSide}
    </header>
  )
}

export default withStore(mapStateToProps)(HeaderBar)
