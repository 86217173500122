import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import ROUTES from '@/router/routes'
import { withRouteId } from '@/router/utils'

interface NavigatorOptions {
  openInNewTab?: boolean
}

class Navigator {
  navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate
  }

  public to = (path: string) => {
    this.navigate(path)
  }

  public withParams = (url: string, params?: any) => {
    if (isEmpty(params)) {
      return url
    }

    return `${url}?${queryString.stringify(params)}`
  }

  public goBack = () => {
    this.navigate(-1)
  }

  public toLogin = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.LOGIN, params))
  }

  public toLogout = () => {
    this.navigate(ROUTES.LOGOUT)
  }

  public toSignUp = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.SIGN_UP, params))
  }

  public toCompleteSignUp = (params?: { token: string }) => {
    this.navigate(this.withParams(ROUTES.COMPLETE_SIGN_UP, params))
  }

  public toDashboard = (companyToVerify?: string) => {
    this.navigate(
      companyToVerify
        ? this.withParams(ROUTES.DASHBOARD, { companyToVerify })
        : ROUTES.DASHBOARD
    )
  }

  public toProfile = (page = 'user-settings') => {
    this.navigate(withRouteId(ROUTES.PROFILE_COMMON, page, 'page'))
  }

  public toDashboardCompany = (companyId: string) => {
    this.navigate(withRouteId(ROUTES.DASHBOARD_COMPANY, companyId))
  }

  public toAllSafeNotes = (
    companyId: string,
    type: 'all' | 'unpaid',
    entrepreneurCompanyId?: string
  ) => {
    const query: any = { type }

    if (entrepreneurCompanyId) {
      query.entrepreneurCompanyId = entrepreneurCompanyId
    }

    this.navigate(
      this.withParams(withRouteId(ROUTES.ALL_SAFES, companyId), query)
    )
  }

  public toCreateTermSheet = (companyId: string, params = {}) => {
    this.navigate(
      this.withParams(
        withRouteId(ROUTES.CREATE_TERMS_SHEET, companyId, 'companyId'),
        params
      )
    )
  }

  public toTermSheet = (id: string, params?: any) => {
    this.navigate(
      this.withParams(withRouteId(ROUTES.VIEW_TERM_SHEET, id), params)
    )
  }

  public toDashboardInnerCompany = (
    companyId: string,
    innerCompanyId: string
  ) => {
    this.navigate(
      withRouteId(
        withRouteId(ROUTES.DASHBOARD_ANGEL_DETAILS, companyId),
        innerCompanyId,
        'innerCompanyId'
      )
    )
  }

  public toCompanyProfile = () => {
    this.navigate(ROUTES.COMPANY_PROFILE)
  }

  public toViewSafeNote = (safeNoteId: string, options?: NavigatorOptions) => {
    const url = withRouteId(ROUTES.VIEW_SAFE, safeNoteId)

    // Unify this option on core level
    if (options?.openInNewTab) {
      window.open(url, '_blank')
      return
    }

    this.navigate(withRouteId(ROUTES.VIEW_SAFE, safeNoteId))
  }

  public toPreviewSafe = () => {
    this.navigate(ROUTES.PREVIEW_SAFE)
  }

  public toCreateSafeNote = (
    companyId: string,
    params?: any,
    options?: NavigatorOptions
  ) => {
    const url = this.withParams(
      withRouteId(ROUTES.CREATE_SAFE, companyId, 'companyId'),
      params
    )

    if (options?.openInNewTab) {
      window.open(url, '_blank')
      return
    }

    this.navigate(url)
  }

  public toHome = () => {
    this.navigate(ROUTES.HOME)
  }

  public toPricing = () => {
    this.navigate(ROUTES.PRICING)
  }

  public toAngels = () => {
    this.navigate(ROUTES.ANGELS)
  }

  public toAgencies = () => {
    this.navigate(ROUTES.AGENCIES)
  }

  public toEntrepreneurs = () => {
    this.navigate(ROUTES.ENTREPRENEURS)
  }
}

export function useNavigator() {
  const navigate = useNavigate()

  return new Navigator(navigate)
}
