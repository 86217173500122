import clsx from 'clsx'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { StoreType, withStore } from '@/common/store'
import { MenuItem } from '@/features/dashboard/components'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import menu from '@/features/dashboard/menu'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'

import styles from './NavigationMenu.module.scss'

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me,
  sortedCompanies: user.sortedCompanies
})

type NavigationMenuProps = ReturnType<typeof mapStateToProps> & {
  collapsed: boolean
  setCollapsed: (type: boolean) => void
}

const NavigationMenu: FC<NavigationMenuProps> = ({
  collapsed,
  sortedCompanies,
  setCollapsed
}) => {
  const { selectedProfile, setSelectedProfile } =
    useDashboardNavigationContext()

  const { id: companyId } = useParams()

  const [selectedRole, setSelectedRole] = useState<ProfileTypes | undefined>()

  const {
    visible: modalActive,
    closeModal,
    openModal
  } = useModal<boolean>(false)
  const { angel, entrepreneur } = sortedCompanies

  const menuItems = useMemo(
    () => menu(entrepreneur, angel),
    [entrepreneur, angel]
  )

  const handleCreateNew = (companyType: ProfileTypes) => {
    setSelectedRole(companyType)
    openModal()
  }

  useEffect(() => {
    setCollapsed(!!companyId)
  }, [companyId])

  return (
    <div
      className={clsx(styles.wrapper, collapsed ? styles.mini : styles.fixed)}
    >
      <aside className={clsx(styles.mainMenu, collapsed && styles.mini)}>
        {modalActive && (
          <AddNewProfileModal
            predefinedRole={selectedRole}
            closeModal={closeModal}
          />
        )}

        <div
          className={clsx(
            styles.sidebarController,
            !collapsed && styles.hidden
          )}
          onClick={() => setCollapsed(!collapsed)}
        >
          <SharedIcons.MinimalisticArrow
            color={Color.neutral400}
            height={9}
            width={4}
          />
        </div>

        <div className={styles.mainMenuInner}>
          <MenuItem id="title" isHeadingItem title="Workspaces" />

          {menuItems.map((item) => (
            <MenuItem
              key={item.id}
              addProfile={handleCreateNew}
              active={selectedProfile === item.id}
              onClick={setSelectedProfile}
              {...item}
            />
          ))}
        </div>
      </aside>
    </div>
  )
}

export default withStore(mapStateToProps)(NavigationMenu)
