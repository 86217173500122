import clsx from 'clsx'
import React, { FC, useCallback, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Info,
  InfoType,
  Radio,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './RoleItem.module.scss'

interface RoleItemProps {
  id: string
  icon: string
  title: string
  info: string
  disabled?: boolean
  description: string
  value: string | undefined
  secondary?: boolean
  pendingSafesNumber?: number
  onSelect?: (value: string) => void
}

const RoleItem: FC<RoleItemProps> = ({
  id,
  icon,
  title,
  info,
  description,
  value,
  disabled,
  onSelect,
  secondary,
  pendingSafesNumber
}: RoleItemProps) => {
  const handleSelect = useCallback(() => {
    if (disabled) return

    onSelect?.(id)
  }, [onSelect, id, disabled])

  const active = id === value

  const MessageComponent = useMemo(() => {
    if (!pendingSafesNumber || !active) return null

    if (pendingSafesNumber && value === ProfileTypes.ANGEL) {
      return (
        <Info
          type={InfoType.SUCCESS}
          textType={TextTypes.BODY_SMALL}
          iconSize={15}
          className={styles.infoBanner}
        >
          You can pay with this role for your {pendingSafesNumber} pending{' '}
          {pendingSafesNumber === 1 ? 'SAFE' : 'SAFEs'}
        </Info>
      )
    }

    return (
      <Info
        type={InfoType.WARNING}
        textType={TextTypes.BODY_SMALL}
        iconSize={15}
        className={styles.infoBanner}
      >
        You cannot pay with this role for your {pendingSafesNumber} pending{' '}
        {pendingSafesNumber === 1 ? 'SAFE' : 'SAFEs'}
      </Info>
    )
  }, [pendingSafesNumber, value, active])

  return (
    <div
      className={clsx(
        styles.container,
        active && styles.active,
        secondary && styles.secondary,
        disabled && styles.disabled
      )}
      onClick={handleSelect}
    >
      <Col items="stretch">
        <div className={styles.mainInfo}>
          <div className={clsx(styles.characterBg, styles[id])}>
            <img src={icon} alt={title} className={styles.roleImg} />
          </div>
          <div className={styles.content}>
            <Text
              weight={FontWeight.SEMIBOLD}
              tooltip={info}
              showTooltipTriggerIcon
              tooltipTitle={title}
            >
              {title}
            </Text>
            <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral500}>
              {description}
            </Text>
          </div>
          <ConditionalRender
            condition={!disabled}
            fallbackElement={
              <div className={styles.comingSoon}>Coming Soon</div>
            }
          >
            <div className={styles.radio}>
              <Radio
                name="role"
                value={id}
                checked={active}
                onChange={handleSelect}
              />
            </div>
          </ConditionalRender>
        </div>
        {MessageComponent}
      </Col>
    </div>
  )
}

export default RoleItem
