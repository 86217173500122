import clsx from 'clsx'
import { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import { BellIcon } from '@/features/dashboard/assets'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  Row,
  Switch,
  Text,
  TextAlign,
  TextTypes,
  Tooltip
} from '@/packages/ui'

import noNotificationsImg from './images/no-notifications.png'
import styles from './Notifications.module.scss'

const notifications = [
  {
    id: 1,
    author: 'Alex Kros',
    message: 'sent a new SAFE request',
    time: '2h ago'
  }
]

const unreadCount = 0

interface NotificationProps {
  size?: number
  color?: Color
  minimalistic?: boolean
  placement?: 'bottom-start' | 'bottom-end'
}

const Notifications: FC<NotificationProps> = ({
  size,
  color = Color.neutral500,
  minimalistic,
  placement = 'bottom-end'
}) => {
  const Empty = (
    <div>
      <Row items="center" justify="between" gap={20} className={styles.header}>
        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          Notifications (0)
        </Text>

        <Row items="center" gap={8}>
          <Text
            className="!tw-leading-snug"
            type={TextTypes.BODY_SMALL}
            color={Color.neutral300}
          >
            Filter by unread
          </Text>
          <Switch />
        </Row>
      </Row>

      <div className={styles.notificationsBody}>
        <img src={noNotificationsImg} width={60} alt="" />
        <Text
          align={TextAlign.CENTER}
          type={TextTypes.BODY_SMALL}
          color={Color.neutral400}
        >
          You have no notification
        </Text>
      </div>
    </div>
  )

  return (
    <ConditionalRender
      condition={!!notifications.length}
      fallbackElement={<BellIcon color={Color.neutral500} />}
    >
      <Tooltip
        trigger="click"
        interactive
        placement={placement}
        className="tw-w-[400px] !tw-py-15 !tw-px-20"
        content={Empty}
      >
        <div
          className={clsx(styles.bellWrapper, minimalistic && styles.mini)}
          onClick={(event) => event.stopPropagation()}
        >
          <BellIcon size={size} color={color} />
          <ConditionalRender condition={unreadCount > 0}>
            <span className={styles.mark}>{notifications.length}</span>
          </ConditionalRender>
        </div>
      </Tooltip>
    </ConditionalRender>
  )
}

export default Notifications
