import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import {
  ISafeNoteDraft,
  ISafeNoteHighlights,
  ISafeNoteRecipient,
  ISafeNoteSignatures,
  SafeNoteDocument,
  SafeNoteNavigation,
  Terms,
  ViewSafeAs
} from '@/features/safe-note'
import { SafeNoteContextProvider } from '@/features/safe-note/components/SafeNote/context'
import { IUser } from '@/features/user'

import styles from './SafeNote.module.scss'

export interface SafeNoteProps {
  safeNoteId?: string
  sender: IUser | null
  recipient?: IUser // only need when save is already sent

  companyName: string
  stateOfIncorporation: string
  recipients: ISafeNoteRecipient[]

  selectedTermsId: Terms | undefined
  discountRate: number | undefined
  discountRateActive: boolean
  valuationCap: number | undefined
  valuationCapActive: boolean
  mfn: boolean

  signatures: ISafeNoteSignatures

  isSafeAccepted?: boolean
  isSignatureAdded?: boolean
  viewAs: ViewSafeAs
  viewMode?: boolean
  onUpdateData: (value: Partial<ISafeNoteDraft>) => void
  highlights: ISafeNoteHighlights
  updateHighlights: (values: Partial<ISafeNoteHighlights>) => void
}

const SafeNote: FC<SafeNoteProps> = (props: SafeNoteProps) => {
  const { recipients, viewMode } = props

  const fullScreen = useFullScreenHandle()

  const [recipientIndex, setRecipientIndex] = useState(0)

  const toggleFullScreen = async () => {
    if (fullScreen.active) {
      await fullScreen.exit()
    } else {
      await fullScreen.enter()
    }
  }

  useEffect(() => {
    const maxId = recipients.length - 1
    if (recipientIndex > maxId) {
      setRecipientIndex(0)
    }
  }, [recipients.length, recipientIndex])

  return (
    <>
      <SafeNoteContextProvider
        {...props}
        recipientIndex={recipientIndex}
        setRecipientIndex={setRecipientIndex}
      >
        <FullScreen handle={fullScreen}>
          <div
            className={clsx(
              styles.safeNoteContainer,
              viewMode && styles.viewMode,
              fullScreen.active && styles.fullScreen
            )}
          >
            <SafeNoteNavigation fullScreen={fullScreen.active} />
            <SafeNoteDocument
              fullScreen={fullScreen.active}
              toggleFullScreen={toggleFullScreen}
            />
          </div>
        </FullScreen>
      </SafeNoteContextProvider>
    </>
  )
}

export default SafeNote
