import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ConditionalRender, HeaderBar, OptionsPopup } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import {
  ISafeNote,
  ISafeNoteDraft,
  ISafeNoteHighlights,
  ISafeNoteSignatures,
  PaymentStep,
  SafeNote,
  Terms,
  ViewSafeAs
} from '@/features/safe-note'
import styles from '@/features/safe-note/layouts/CreateSafeNoteLayout/CreateSafeNoteLayout.module.scss'
import { AcceptSafeModal } from '@/features/safe-note/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal, useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  Row,
  StepperNavigation,
  Text,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { IStep } from '@/packages/ui/stepper/types'
import { useNavigator } from '@/router'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  fetchOne: safeNote.fetchOne,
  loading: safeNote.functionLoading.fetchOne
})

const defaultSignaturesValue: ISafeNoteSignatures = {
  senderSignature: undefined,
  senderSignatureName: undefined,
  senderSignatureDate: undefined,
  recipientSignature: undefined,
  recipientSignatureName: undefined,
  recipientSignatureDate: undefined
}

const options = [
  { id: 'sendReminder', label: 'Send reminder' },
  { id: 'deleteSafe', label: 'Delete SAFE' }
]

const angelSteps: IStep[] = [
  {
    id: 'reviewAndSign',
    title: 'Review and Sign'
  },
  {
    id: 'pay',
    title: 'Make Investment'
  }
]

const ViewSafeNew: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { fetchOne, loading } = props

  const { id } = useParams()
  const navigate = useNavigator()

  const acceptSafeModal = useModal(false)
  const createProfileModal = useModal(false)

  const stepper = useStepper(0, angelSteps.length)

  const [safeNote, setSafeNote] = useState<ISafeNote | undefined>()
  const [signatures, setSignatures] = useState<ISafeNoteSignatures>(
    () => defaultSignaturesValue
  )
  const [highlights, setHighlights] = useSmartState<ISafeNoteHighlights>({
    mfn: false,
    helpSetTerms: false,
    discountRate: false,
    valuationCap: false
  })

  // util variables
  const viewAsRecipient = safeNote?.viewAs === ViewSafeAs.RECIPIENT
  const viewAsSender = safeNote?.viewAs === ViewSafeAs.SENDER
  const isSafeAccepted = !!safeNote?.recipientCompanyId

  const fetchSafeNote = async () => {
    if (!id) {
      navigate.toDashboard()
      return
    }

    const response = await fetchOne({
      data: {
        id
      },
      options: {
        onError: () => {
          navigate.toDashboard()
        }
      }
    })

    setSafeNote(response?.id ? response : undefined)

    setSignatures(
      response?.id
        ? {
            senderSignatureName: response.senderSignName,
            senderSignatureDate: response.senderSignDate,
            senderSignature: { signature: response.senderSignature },

            recipientSignature: { signature: response.recipientSignature },
            recipientSignatureDate: response.recipientSignDate,
            recipientSignatureName:
              response.recipientSigneName || response.recipient.fullName
          }
        : defaultSignaturesValue
    )
  }

  useEffect(() => {
    fetchSafeNote()
  }, [id])

  const handleUpdateData = (data: Partial<ISafeNoteDraft>) => {
    if (data.signatures) setSignatures(data.signatures)
  }

  const SenderHeaderBar = (
    <HeaderBar
      middleComponent={
        <Heading
          type={HeadingTypes.H3}
          weight={FontWeight.BOLD}
          color={Color.neutral400}
        >
          View SAFE
        </Heading>
      }
      rightSide={
        <OptionsPopup options={options} onOptionClick={noop}>
          <div className={styles.optionsWrapper}>
            <SharedIcons.Dots color={Color.neutral400} />
          </div>
        </OptionsPopup>
      }
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const RecipientPendingSafeHeader = (
    <HeaderBar
      rightSide={
        <Row gap={12}>
          <Button
            width="default"
            appearance="secondary"
            className="!tw-w-[154px]"
          >
            Decline
          </Button>
          <Button
            className="!tw-w-[154px]"
            addonAfter={<SharedIcons.Arrow className="tw-ml-8" size={20} />}
            onClick={() => acceptSafeModal.openModal()}
          >
            Accept
          </Button>
        </Row>
      }
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const Stepper = (
    <div className={styles.stepperWrapper}>
      <StepperNavigation
        type="small"
        activeStep={stepper.currentStep}
        className={styles.navigation}
        steps={angelSteps}
        itemTextType={TextTypes.BODY_DEFAULT}
        markPreviousAsCompleted={false}
        allowManuallyChangeStep
      />
    </div>
  )

  const AngelProcessNavigation = (
    <Row items="stretch" gap={12}>
      {stepper.currentStep > 0 && (
        <div>
          <Button
            uppercase
            width="fit"
            appearance="secondary"
            disabled={loading}
            onClick={stepper.prevStep}
            className={styles.backButton}
          >
            <SharedIcons.Arrow size={20} color={Color.neutral300} />
          </Button>
        </div>
      )}

      <Button
        uppercase
        width="fit"
        onClick={stepper.nextStep}
        disabled={!signatures.recipientSignatureDate || loading}
        className="!tw-py-14 !tw-px-40"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading
          ? 'Loading...'
          : stepper.isLastStep
          ? 'Complete Investment '
          : 'Next'}
        {!stepper.isLastStep && (
          <SharedIcons.Arrow size={20} className="tw-ml-5" />
        )}
      </Button>
    </Row>
  )

  const RecipientAcceptedSafeHeader = (
    <HeaderBar
      middleComponent={Stepper}
      rightSide={AngelProcessNavigation}
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const AcceptedSafeBanner = (
    <Row items="center" justify="center" className={styles.acceptedBanner}>
      <SharedIcons.Warning
        size={14}
        color={Color.success400}
        className="tw-mr-8"
      />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.success400}
        weight={FontWeight.SEMIBOLD}
      >
        You’ve accepted the SAFE from {safeNote?.sender?.fullName} @{' '}
        {safeNote?.senderCompany?.name}!
      </Text>
    </Row>
  )

  const renderRecipientSafeTitle = () => {
    let title = `SAFE note (${safeNote?.senderCompany.name})`
    let description = 'View a summary of the SAFE terms and accept or decline.'

    if (safeNote?.recipientCompanyId) {
      title = 'Add you signature'
      description =
        'To make an investment, add your signature at the end of the document.'
    }

    if (stepper.currentStep === 1) {
      title = 'Complete your investment'
      description = 'Select how you want to make an investment.'
    }

    return (
      <Col items="center" className="tw-mb-24">
        <Heading type={HeadingTypes.H2}>{title}</Heading>
        <Text color={Color.neutral400} className="tw-mt-8">
          {description}
        </Text>
      </Col>
    )
  }

  const RecipientHeaderBar = isSafeAccepted
    ? RecipientAcceptedSafeHeader
    : RecipientPendingSafeHeader

  if (loading || !safeNote) return <FullScreenLoading loading />

  return (
    <div className={clsx(styles.wrapper, styles.wide)}>
      <ConditionalRender condition={acceptSafeModal.visible}>
        <AcceptSafeModal
          closeModal={acceptSafeModal.closeModal}
          onAccepted={fetchSafeNote}
          safeNote={safeNote}
          toCreateCompany={createProfileModal.openModal}
        />
      </ConditionalRender>

      <ConditionalRender condition={createProfileModal.visible}>
        <AddNewProfileModal
          predefinedRole={ProfileTypes.ANGEL}
          closeModal={createProfileModal.closeModal}
        />
      </ConditionalRender>

      {viewAsSender ? SenderHeaderBar : RecipientHeaderBar}
      {viewAsRecipient && isSafeAccepted && AcceptedSafeBanner}

      <div className={styles.container}>
        <div className={styles.containerInner}>
          {viewAsRecipient && renderRecipientSafeTitle()}

          {stepper.currentStep === 0 && (
            <SafeNote
              safeNoteId={id}
              sender={safeNote.sender}
              recipient={safeNote.recipient}
              companyName={safeNote.senderCompany.name}
              stateOfIncorporation={
                safeNote.senderCompany.stateOfIncorporation || ''
              }
              recipients={[
                {
                  name: safeNote.recipient.fullName,
                  email: safeNote.recipient.email,
                  img: safeNote.recipient.image,
                  amount: safeNote.safeAmount
                }
              ]}
              mfn={safeNote.mfn}
              //
              selectedTermsId={safeNote.mfn ? Terms.MFN : Terms.HELP_SET_TERMS}
              discountRate={safeNote.discountRate}
              discountRateActive={!!safeNote.discountRate}
              valuationCap={safeNote.valuationCap}
              valuationCapActive={!!safeNote.valuationCap}
              //
              viewMode
              viewAs={safeNote.viewAs}
              onUpdateData={handleUpdateData}
              highlights={highlights}
              updateHighlights={setHighlights}
              //
              signatures={signatures}
              isSafeAccepted={!!safeNote.recipientCompanyId}
              isSignatureAdded={
                (viewAsSender && !!safeNote?.senderSignDate) ||
                (viewAsRecipient && !!safeNote?.recipientSignDate)
              }
            />
          )}

          {stepper.currentStep === 1 && <PaymentStep safeNote={safeNote} />}
        </div>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(ViewSafeNew)
