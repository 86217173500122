import { IPayment } from '@/features/payment'
import { ICompany } from '@/features/profile/types'
import { ISafeNoteRecipient } from '@/features/safe-note'
import { ITermSheet } from '@/features/term-sheet/api/types'
import { IUser } from '@/features/user'

export enum SafeNoteStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  CANCELLED = 'cancelled',
  SIGNED = 'signed'
}

export enum ViewSafeAs {
  SENDER = 'sender',
  RECIPIENT = 'recipient',
  TEAM_MEMBER = 'teamMember'
}

export enum SignSafeAs {
  SENDER = 'sender',
  RECIPIENT = 'recipient'
}

export enum SafeFor {
  ANGEL = 'angel',
  AGENCY = 'agency'
}

export interface CreateSafeNoteDto {
  senderCompanyId: string
  termSheetId?: string
  recipients: ISafeNoteRecipient[]
  discountRate?: number
  valuationCap?: number
  mfn?: boolean
  paymentId?: string
  senderSignName: string
  senderSignature: string
  draft?: boolean
}

export interface SignSafeDto {
  signAs: SignSafeAs
  signature: string
  name: string
}

export interface SafeNotePermissions {
  canView: boolean
  canSign: boolean
}

export interface ISafeNote {
  canSign: boolean
  createdAt: boolean
  data: any
  discountRate: number | undefined
  id: string
  mfn: boolean
  paid: boolean
  payment: IPayment | null
  paymentId: string | null
  recipient: IUser
  recipientCompanyId: string | null
  recipientCompany: ICompany | null
  recipientId: string
  recipientSignDate: Date | null
  recipientSignName: string | null
  recipientSignature: string | null
  safeAmount: number
  sender: IUser
  senderCompany: ICompany
  senderCompanyId: string
  senderSignDate: Date
  senderSignName: string
  senderSignature: string
  status: SafeNoteStatus
  termSheet: ITermSheet | null
  termSheetId: string | null
  updatedAt: Date
  valuationCap: number | undefined
  viewAs: ViewSafeAs
}
