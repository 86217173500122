import { observer } from 'mobx-react-lite'
import { FC, useCallback } from 'react'

import { useToast } from '@/common/hooks'
import { StoreType, useStore } from '@/common/store'
import {
  PayAs,
  PaymentMethod,
  PaymentMethodData,
  PaymentOverview,
  PaymentType
} from '@/features/payment'
import { PaymentBridgeChildrenProps } from '@/features/payment/components'
import { ISafeNote } from '@/features/safe-note'

interface PaymentStepProps {
  safeNote?: ISafeNote
}

const checkIfSafeNoteCreated = () => {}
const createSafeNote = () => {}
const updateData = () => {}

const PaymentStep: FC<PaymentStepProps> = (props) => {
  const { safeNote } = props
  const { createSafeNotePaymentIntent } = useStore(
    (store: StoreType) => store.payment
  )

  const { showInfo } = useToast()

  const data = props.safeNote

  const decideToDisableButton = useCallback(
    (method: PaymentMethod) => {
      if (safeNote?.paid) return

      if (method === PaymentMethod.RECORD_PAYMENT) {
        // setNextDisabled(false)
      }

      // setNextDisabled(true)
    },
    [data?.paid]
  )

  const handleSelectPaymentMethod = useCallback(
    (method: PaymentMethod) => {
      decideToDisableButton(method)

      if (method === PaymentMethod.RECORD_PAYMENT) {
        // updateData({ paid: true }, true)
      }
      // updateData({ paid: false }, true)
    },
    [decideToDisableButton]
  )

  const handlePay = useCallback(
    ({
        processPayment,
        constructCardData,
        constructBankTransferData
      }: PaymentBridgeChildrenProps) =>
      async (_data: PaymentMethodData) => {
        if (!safeNote) return

        if (safeNote.paid) {
          showInfo('Payment was already completed!')
          return
        }

        if (_data.method === PaymentMethod.CARD) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.CARD,
              data: constructCardData(_data),
              safeNoteId: safeNote.id,
              payAs: PayAs.ENTREPRENEUR
            }
          })

          const status = await processPayment(PaymentType.CARD, payment, _data)
          // updateData(
          //   {
          //     paymentStatus: status,
          //     paid: status === PaymentStatus.SUCCESS
          //   },
          //   true
          // )
        }

        if (_data.method === PaymentMethod.BANK_TRANSFER) {
          const payment = await createSafeNotePaymentIntent({
            data: {
              type: PaymentType.BANK_TRANSFER,
              data: constructBankTransferData(_data),
              safeNoteId: safeNote.id,
              payAs: PayAs.ENTREPRENEUR
            }
          })

          const status = await processPayment(
            PaymentType.BANK_TRANSFER,
            payment,
            _data
          )
          // updateData(
          //   {
          //     paymentStatus: status,
          //     paid: status === PaymentStatus.SUCCESS
          //   },
          //   true
          // )
        }
      },
    [
      checkIfSafeNoteCreated,
      createSafeNote,
      updateData,
      createSafeNotePaymentIntent
    ]
  )

  if (!safeNote) return <></>

  return (
    <PaymentOverview
      platformFee={0}
      stripeFee={0}
      onSelectPaymentMethod={handleSelectPaymentMethod}
      onPay={handlePay}
      recipientCompany={safeNote.senderCompany.name}
      recipientName={safeNote.sender.fullName}
      investorName={safeNote.recipient.fullName}
      investorCompany={safeNote.recipientCompany?.name || '-'}
      investmentAmount={safeNote?.safeAmount}
    />
  )
}

export default observer(PaymentStep)
