import React, { FC, useState } from 'react'

import { ArrowNavigation, ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { SafesList } from '@/features/dashboard/modals/IncomingSafesModal/parts'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { IModalWithCloseFn, Loader, Modal, Row } from '@/packages/ui'

import styles from './IncomingSafesModal.module.scss'

const MODAL_ID = 'pending-safes-modal'

interface IncomingSafesModalProps extends IModalWithCloseFn {
  safes: ISafeNote[]
  filterOutPendingSafes: (id: string, companyId?: string) => void
  toCreateProfile: () => void
  availableCompanies: ICompany[]
}

const mapStateToProps = ({ safeNote }: StoreType) => ({
  acceptSafeNote: safeNote.acceptSafeNote,
  loading: safeNote.functionLoading.acceptSafeNote
})

const IncomingSafesModal: FC<
  IncomingSafesModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    filterOutPendingSafes,
    toCreateProfile,
    availableCompanies,
    safes,
    acceptSafeNote,
    loading,
    closeModal
  } = props

  const [activeSafe, setActiveSafe] = useState<number>(0)
  const [step] = useState<'invites' | 'decline'>('invites')

  const currentSafe = safes?.[activeSafe]

  if (!Array.isArray(safes) || !currentSafe) {
    return null
  }

  const handleDecline = () => {
    if (safes.length > 1) {
      filterOutPendingSafes(currentSafe.id)
      setActiveSafe(0)
    } else {
      filterOutPendingSafes(currentSafe.id)
      closeModal?.()
    }

    ToastService.showSuccess('SAFE was declined')
  }

  const handleAccept = async (companyId: string) => {
    await acceptSafeNote({
      data: { id: currentSafe.id, companyId },
      options: {
        onSuccess: () => {
          if (safes.length > 1) {
            filterOutPendingSafes(currentSafe.id, companyId)
            setActiveSafe(0)
          } else {
            filterOutPendingSafes(currentSafe.id, companyId)

            closeModal?.()
          }

          ToastService.showSuccess(
            `SAFE from ${currentSafe.sender.fullName} was accepted`
          )
        }
      }
    })
  }

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="300px" />}
      >
        {safes.length > 1 && (
          <Row className={styles.navigation}>
            <ArrowNavigation
              active={activeSafe}
              setActive={setActiveSafe}
              size={safes.length}
            />
          </Row>
        )}

        {step === 'invites' && (
          <SafesList
            id={currentSafe.id}
            mfn={currentSafe.mfn}
            senderCompany={currentSafe.senderCompany}
            amount={currentSafe.safeAmount}
            discount={currentSafe.discountRate}
            valuation={currentSafe.valuationCap}
            sender={currentSafe.sender}
          />
        )}
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(IncomingSafesModal)
