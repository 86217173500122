import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import {
  PaymentBridge,
  PaymentBridgeChildrenProps,
  PaymentDetails,
  PaymentMethod,
  PaymentMethodData,
  PaymentOptions,
  PaymentStatus
} from '@/features/payment'
import { Button, IFile, Row } from '@/packages/ui'

import styles from './PaymentModule.module.scss'

interface PaymentModuleProps {
  cashAmount?: number
  platformFee: number
  stripeFee: number
  onPay?: (
    data: PaymentBridgeChildrenProps
  ) => (data: PaymentMethodData) => void
  onPayLater?: () => void
  onSelect?: (method: PaymentMethod) => void
  status?: PaymentStatus
  details?: any
  withRecordPayment?: boolean
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
}

const PaymentModule: FC<PaymentModuleProps> = ({
  platformFee,
  cashAmount = 0,
  onPay,
  onPayLater,
  onSelect,
  status,
  stripeFee,
  details,
  setRecordPaymentFiles,
  withRecordPayment = true
}) => (
  <PaymentBridge
    initialDetails={details}
    payAmount={cashAmount + platformFee}
    initialStatus={status}
  >
    {(paymentBridgeProps: PaymentBridgeChildrenProps) => (
      <div className={styles.container}>
        <PaymentOptions
          title="Select investment method"
          withRecordPayment={withRecordPayment}
          onPayLater={onPayLater}
          onPay={onPay?.(paymentBridgeProps)}
          cashAmount={cashAmount}
          onSelect={onSelect}
          setRecordPaymentFiles={setRecordPaymentFiles}
        />

        <PaymentDetails
          cashAmount={cashAmount}
          platformFee={platformFee}
          stripeFee={stripeFee}
        />

        <ConditionalRender condition>
          <Row justify="end" className="tw-mt-24">
            <Button width="default" uppercase>
              Pay now
            </Button>
          </Row>
        </ConditionalRender>
      </div>
    )}
  </PaymentBridge>
)

export default PaymentModule
