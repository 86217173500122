import 'tippy.js/animations/shift-away-subtle.css'
import './tippy-styles.scss'

import Tippy, { TippyProps } from '@tippyjs/react'
import clsx from 'clsx'
import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes, TooltipAppearance } from '@/packages/ui'

import styles from './Tooltip.module.scss'
import { TooltipKind } from './types'

interface IProps {
  kind?: TooltipKind
  title?: string
  appearance?: TooltipAppearance
  disabled?: boolean
  small?: boolean
  asComponent?: boolean
}

type TooltipProps = PropsWithChildren<
  Omit<TippyProps, 'children' | 'animation'> & IProps
>

const getIcon = (type: TooltipKind, small?: boolean): ReactNode | null => {
  const sizeProps = {
    width: small ? 12 : 15,
    height: small ? 12 : 15
  }

  switch (type) {
    case TooltipKind.INFO: {
      return <SharedIcons.Info {...sizeProps} color={Color.primary400} />
    }
    case TooltipKind.WARNING: {
      return <SharedIcons.Warning {...sizeProps} color={Color.warning300} />
    }
    default: {
      return null
    }
  }
}

const Tooltip: FC<TooltipProps> = ({
  kind,
  small,
  content,
  children,
  title,
  asComponent,
  placement = 'top',
  className,
  disabled,
  appearance,
  ...rest
}: TooltipProps) => {
  const TooltipContent = (
    <div
      className={clsx(
        styles.tooltip,
        appearance && styles[appearance],
        className
      )}
    >
      <ConditionalRender condition={!!title}>
        <Text
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
          color={Color.neutral500}
          className="tw-mb-[6px]"
        >
          {title}
        </Text>
      </ConditionalRender>

      <ConditionalRender
        condition={asComponent}
        fallbackElement={
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            {content} {title}
          </Text>
        }
      >
        {content}
      </ConditionalRender>
    </div>
  )

  if (disabled) return children as JSX.Element

  return (
    <Tippy
      arrow={!!kind}
      content={TooltipContent}
      placement={placement}
      animation="shift-away-subtle"
      {...rest}
    >
      {!!kind && !children ? (
        <div className={styles.iconHolder}>{getIcon(kind, small)}</div>
      ) : (
        (children as JSX.Element)
      )}
    </Tippy>
  )
}

export default Tooltip
