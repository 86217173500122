import clsx from 'clsx'
import { noop } from 'lodash'
import React, { FC, useCallback } from 'react'

import { TextTypes } from '@/packages/ui'

import { IStep } from '../types'
import { Item } from './Item'
import styles from './StepperNavigation.module.scss'

interface StepperNavigationProps {
  steps: IStep[]
  activeStep?: number
  onSelectStep?: (index: number) => void
  itemTextType?: TextTypes
  markPreviousAsCompleted?: boolean
  allowManuallyChangeStep?: boolean
  className?: string
  type?: 'default' | 'small' | 'circled'
}

const StepperNavigation: FC<StepperNavigationProps> = ({
  steps,
  type = 'default',
  activeStep = 0,
  onSelectStep,
  markPreviousAsCompleted = true,
  itemTextType,
  allowManuallyChangeStep,
  className
}: StepperNavigationProps) => {
  const renderSteps = useCallback(
    () =>
      steps
        .filter((step) => !step.hidden)
        .map((step: IStep, index: number) => (
          <Item
            index={index}
            type={type}
            key={step.id || index}
            active={
              markPreviousAsCompleted
                ? index <= activeStep
                : index === activeStep
            }
            textType={itemTextType}
            onClick={
              allowManuallyChangeStep ? () => onSelectStep?.(index) : noop
            }
            notClickable={!allowManuallyChangeStep}
            {...step}
          />
        )),
    [
      steps,
      onSelectStep,
      allowManuallyChangeStep,
      markPreviousAsCompleted,
      activeStep
    ]
  )

  return <ul className={clsx(styles.container, className)}>{renderSteps()}</ul>
}

export default StepperNavigation
