import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Notifications } from '@/common/components/HeaderBar/Notifications'
import { CompanyOptions, DashboardTeamMembers } from '@/features/dashboard'
import { TeamMemberPermission } from '@/features/user'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

import styles from './DashboardCompanyHeader.module.scss'

const DashboardCompanyHeader: FC<{
  company: any
  anotherOwner?: string
  permission?: TeamMemberPermission
}> = (props) => {
  const { permission, anotherOwner, company = {} } = props

  const isCreator = !anotherOwner && permission === TeamMemberPermission.CREATE

  const CompanyName = (
    <Row items="center" gap={10}>
      <Text
        weight={FontWeight.BOLD}
        color={Color.neutral500}
        type={TextTypes.BODY_MAIN}
      >
        {company.name}
      </Text>

      {/* TODO hidden for now */}
      {/* <VerificationStatusBadge company={company} isCreator={isCreator} /> */}
    </Row>
  )

  const NotMineCompanyName = (
    <Col>
      {CompanyName}
      <Text
        weight={FontWeight.SEMIBOLD}
        color={Color.neutral300}
        type={TextTypes.BODY_SMALL}
      >
        Owner: <span className={styles.ownerName}>{anotherOwner}</span>
      </Text>
    </Col>
  )

  return (
    <section className={styles.companyInfo}>
      <Row items="center" justify="between" gap={20}>
        <Row
          items="center"
          justify={!!anotherOwner ? 'between' : 'start'}
          className={!!anotherOwner ? 'tw-w-full' : ''}
        >
          <Row items="center">
            <Avatar
              size={!!anotherOwner ? 50 : 42}
              className={styles.companyLogo}
              name={company.name}
              image={company.image}
            />

            <ConditionalRender
              condition={!!anotherOwner}
              fallbackElement={CompanyName}
            >
              {NotMineCompanyName}
            </ConditionalRender>
          </Row>

          <div className={styles.divider} />

          <Row items="center">
            <div className={styles.action}>
              <Notifications
                size={28}
                color={Color.neutral400}
                minimalistic
                placement="bottom-start"
              />
            </div>

            <ConditionalRender condition={!anotherOwner}>
              <div className={styles.action}>
                <CompanyOptions company={company} />
              </div>
            </ConditionalRender>
          </Row>
        </Row>

        <ConditionalRender condition={!anotherOwner}>
          <DashboardTeamMembers
            companyId={company.id}
            owner={company.owner}
            companyName={company.name}
            teamMembers={company.teamMembers || []}
          />
        </ConditionalRender>
      </Row>
    </section>
  )
}

export default DashboardCompanyHeader
