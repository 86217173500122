import React from 'react'

import { RecipientStep, ReviewStep, TermsStep } from '@/features/safe-note'
import { useCreateSafeNoteContext } from '@/features/safe-note/context/CreateSafeNoteContext'

const CreateSafeNoteView = () => {
  const { currentStep } = useCreateSafeNoteContext()

  const ComponentMapper = [
    <RecipientStep key="recipientStep" />,
    <TermsStep key="termsStep" />,
    <ReviewStep key="reviewStep" />
  ]

  // useEffect(() => {
  //   const safeNoteId = searchParams.get('safeNoteId')
  //
  //   if (safeNoteId) {
  //     navigate.toViewSafeNote(safeNoteId)
  //   }
  // }, [])

  // const checkIfSafeNoteCreated = useCallback(async () => {
  //   const id = searchParams.get('safeNoteId')
  //
  //   if (!id) return undefined
  //
  //   try {
  //     return fetchOne({ data: { id } })
  //   } catch (err) {
  //     return false
  //   }
  // }, [searchParams])

  // const createSafeNote = useCallback(
  //     async (extraData?: any) => {
  //       const { data } = state
  //
  //       if (!currentCompany) {
  //         showWarning(
  //             "Cannot create a SAFE. Seems like you do not have entrepreneur profile. Let's get one first"
  //         )
  //         return undefined
  //       }
  //
  //       const response = await createSafeNoteRequest({
  //         data: {
  //           termSheetId: state.termSheetId,
  //           senderCompanyId: companyId,
  //           recipientEmail: data.recipientEmail,
  //           recipientName: data.recipientName,
  //           mfn: data.mfn,
  //           discountRate: data?.discountRate ? Number(data?.discountRate) : 0,
  //           safeAmount: Number(data?.safeAmount),
  //           cashAmount: Number(data?.cashAmount),
  //           paymentId: data.paymentId,
  //           signature: data.signatureData?.signature,
  //           signName: data.signatureData?.name,
  //           safeFor: data.safeFor,
  //           ...(data?.valuationCap
  //               ? {
  //                 safeAllowance: Number(data?.safeAllowance),
  //                 safeAllocation: Number(data?.safeAllocation)
  //               }
  //               : {}),
  //           ...extraData
  //         }
  //       })
  //
  //       if (response) {
  //         setSearchParams((prev) => ({
  //           ...prev,
  //           safeNoteId: response.id
  //         }))
  //       }
  //
  //       return response
  //     },
  //     [companyId, state.data]
  // )

  // useEffect(() => {
  //   const header = document.querySelector('#safe-note-layout__header header')
  //   const content = document.querySelector('#safe-note-layout__content')
  //
  //   if (currentStep === 2) {
  //     if (header) {
  //       header.classList.add(styles.static)
  //     }
  //
  //     if (content) {
  //       content.classList.add(styles.fitContent)
  //     }
  //   } else if (header) {
  //     header.classList.remove(styles.static)
  //
  //     if (content) {
  //       content.classList.remove(styles.fitContent)
  //     }
  //   }
  //
  //   if (currentStep === 3 && content) {
  //     content.classList.add(styles.fitContent)
  //   }
  // }, [currentStep])

  // useEffect(() => {
  //   const data = searchParams.get('data')
  //
  //   if (!data) return
  //
  //   const {
  //     id,
  //     mfn,
  //     recipient,
  //     discountRate,
  //     valuationCap,
  //     safeAllocation,
  //     safeAllowance
  //   } = JSON.parse(decodeURIComponent(data) || '{}')
  //
  //   const newState: any = {
  //     safeFor: SafeFor.ANGEL,
  //     recipientEmail: recipient
  //   }
  //
  //   if (mfn) {
  //     newState.mfn = true
  //     newState.selectedTermsId = Terms.MFN
  //   }
  //
  //   if (discountRate || valuationCap) {
  //     newState.selectedTermsId = Terms.HELP_SET_TERMS
  //     newState.discountRateActive = !!discountRate
  //     newState.valuationCapActive = !!valuationCap
  //     newState.discountRate = discountRate
  //     newState.valuationCap = valuationCap
  //     newState.safeAllocation = safeAllocation
  //     newState.safeAllowance = safeAllowance
  //   }
  //
  //   setState({ createTermSheetMode: true, termSheetId: id })
  //   updateTempData(newState, true)
  // }, [])

  return ComponentMapper[currentStep]
}

export default CreateSafeNoteView
