import clsx from 'clsx'
import React, {
  FC,
  FunctionComponent,
  MouseEvent,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { Avatar, ConditionalRender } from '@/common/components'
import { IconProps } from '@/common/hocs'
import { CollapseTransition } from '@/common/transitions'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './MenuItem.module.scss'

interface MenuItemProps {
  id: ProfileTypes | 'title'
  active?: boolean
  isHeadingItem?: boolean
  title: string
  subMenu?: ICompany[]
  icon?: FunctionComponent<IconProps>
  description?: string
  addProfile?: (type: ProfileTypes) => void
  onClick?: (type: ProfileTypes) => void
}

const MenuItem: FC<MenuItemProps> = ({
  id,
  title,
  active,
  addProfile,
  onClick,
  subMenu,
  icon: Icon,
  description,
  isHeadingItem
}: MenuItemProps) => {
  const { id: companyId } = useParams()
  const navigator = useNavigator()

  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    onClick?.(id as ProfileTypes)
    setExpanded(true)
  }

  const handleArrowClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setExpanded((prev) => !prev)
  }

  useEffect(() => {
    setExpanded(!!active)
  }, [active])

  if (isHeadingItem) {
    return (
      <div className={styles.headingItem}>
        <Text
          weight={FontWeight.SEMIBOLD}
          type={TextTypes.BODY_EXTRA_SMALL}
          color={Color.neutral300}
        >
          {title.toUpperCase()}
        </Text>
      </div>
    )
  }

  return (
    <li onClick={handleClick} className={styles.menuItem}>
      <div
        className={clsx(
          styles.menuItemInner,
          active && !companyId && styles.active
        )}
      >
        <Col gap={!!description ? 6 : 0}>
          <Row items="center" gap={10} justify="between">
            <Row items="center" gap={6}>
              {Icon?.({ color: active ? Color.primary500 : Color.neutral500 })}{' '}
              <Text
                color={active ? Color.primary500 : Color.neutral400}
                type={TextTypes.BODY_SMALL}
                weight={FontWeight.SEMIBOLD}
                className="tw-whitespace-nowrap"
              >
                {title} ({subMenu?.length || 0})
              </Text>
            </Row>

            <ConditionalRender condition={!!subMenu && !!subMenu?.length}>
              <div className={styles.arrowWrapper} onClick={handleArrowClick}>
                <SharedIcons.MinimalisticArrow
                  height={9}
                  width={4}
                  color={Color.neutral300}
                  className={clsx(styles.arrow, expanded && styles.active)}
                />
              </div>
            </ConditionalRender>
          </Row>
        </Col>
      </div>

      {!!subMenu?.length && (
        <CollapseTransition initial duration={0.2} collapsed={expanded}>
          <div className={styles.companiesList}>
            {subMenu.map((item) => (
              <Row
                key={item.name}
                items="center"
                gap={8}
                className={clsx(
                  styles.company,
                  companyId === item.id && styles.activeCompany
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  navigator.toDashboardCompany(item.id)
                }}
              >
                <Avatar
                  name={item.name}
                  image={item.image}
                  size={32}
                  className={clsx(
                    styles.avatar,
                    companyId === item.id && styles.active
                  )}
                />
                <Text
                  type={TextTypes.BODY_SMALL}
                  color={Color.neutral400}
                  weight={FontWeight.MEDIUM}
                  className="tw-whitespace-nowrap"
                >
                  {item.name}
                </Text>
              </Row>
            ))}
            <Row
              items="center"
              gap={8}
              className={styles.company}
              onClick={(e) => {
                e.stopPropagation()
                addProfile?.(id as ProfileTypes)
              }}
            >
              <SharedIcons.PlusThin size={25} color={Color.primary500} />
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral400}
                weight={FontWeight.MEDIUM}
                className="tw-whitespace-nowrap"
              >
                Create new
              </Text>
            </Row>
          </div>
        </CollapseTransition>
      )}
    </li>
  )
}

export default MenuItem
