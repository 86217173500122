import clsx from 'clsx'
import cloneDeep from 'lodash/cloneDeep'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import { HeaderBar, OptionsPopup } from '@/common/components'
import { IPopupOption } from '@/common/components/OptionsPopup/OptionsPopup'
import { useToast } from '@/common/hooks'
import { WarningModal } from '@/common/modals'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { getPermission } from '@/features/profile/utils'
import {
  ISafeNoteDraft,
  ISafeNoteHighlights,
  SafeNoteSummary
} from '@/features/safe-note'
import { CreateSafeNoteContextProvider } from '@/features/safe-note/context/CreateSafeNoteContext'
import {
  safeResponseToState,
  safeStateToQuery
} from '@/features/safe-note/helpers'
import { createSafeSteps } from '@/features/safe-note/utils'
import { TeamMemberPermission } from '@/features/user'
import { useModal, useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  FullScreenLoading,
  Row,
  StepperNavigation,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { useNavigator } from '@/router'
import * as process from 'process'

import styles from './CreateSafeNoteLayout.module.scss'

const mapStateToProps = ({ user, safeNote }: StoreType) => ({
  me: user.me,
  companies: user.sortedCompanies,
  loading: safeNote.functionLoading.create,
  generatePdfLoading: safeNote.functionLoading.generatePdf,
  createSafeNote: safeNote.create,
  fetchOne: safeNote.fetchOne,
  fetchDraftLoading: safeNote.functionLoading.fetchOne
})

const CreateSafeNoteLayout: FC<ReturnType<typeof mapStateToProps>> = (
  props
) => {
  const {
    me,
    fetchOne,
    companies,
    loading,
    generatePdfLoading,
    createSafeNote,
    fetchDraftLoading
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const { companyId } = useParams()

  const draftId = searchParams.get('draftId')

  const [nextDisabled, setNextDisabled] = useState(false)
  const [safeNote, setSafeNote] = useSmartState<ISafeNoteDraft>({
    mfn: false,
    selectedTermsId: undefined,
    discountRateActive: false,
    discountRate: undefined,
    valuationCapActive: false,
    valuationCap: undefined,

    signatures: {
      senderSignature: undefined,
      senderSignatureName: undefined,
      senderSignatureDate: undefined,

      recipientSignature: undefined,
      recipientSignatureDate: undefined,
      recipientSignatureName: undefined
    },

    recipients: []
  })

  const [highlights, setHighlights] = useSmartState<ISafeNoteHighlights>({
    mfn: false,
    helpSetTerms: false,
    discountRate: false,
    valuationCap: false
  })

  const { visible, openModal, closeModal } = useModal(false)

  const navigate = useNavigator()
  // const location = useLocation()
  // const safeNoteId = searchParams.get('safeNoteId')

  const { showWarning } = useToast()

  const currentCompany = useMemo(
    () => companies.entrepreneur.find((item) => item.id === companyId),
    [companyId, companies]
  )

  const {
    currentStep,
    prevStep,
    setCurrentStep,
    nextStep,
    completed,
    isLastStep
  } = useStepper(0, 3)

  const options: IPopupOption[] = [
    {
      id: 'draft',
      label: draftId ? 'Save' : 'Save as draft'
    }
  ]

  const handleSelectStep = (index: number) => {
    if (!nextStep || index < currentStep) {
      setCurrentStep(index)
    }
  }

  const onCreate = async (draft = false) => {
    if (!companyId) return

    await createSafeNote({
      data: safeStateToQuery({ safeNote, companyId, draft }),
      options: {
        onSuccess: (response?: any) => {
          if (draft) {
            if (response?.id) {
              setSearchParams((prev) => ({
                ...prev,
                draftId: response.id
              }))
              setSafeNote({ id: response.id })
              ToastService.showSuccess(
                'SAFE note was successfully saved as draft'
              )
            }
          } else {
            nextStep()
          }
        }
      }
    })
  }

  const fetchDraftSafe = async () => {
    if (!draftId) {
      navigate.toDashboard()
      return
    }

    const response = await fetchOne({ data: { id: draftId } })

    if (response?.id) {
      setSafeNote(safeResponseToState(response))
    } else if (companyId) {
      navigate.toCreateSafeNote(companyId)
    } else {
      navigate.toDashboard()
    }
  }

  const handleNext = async () => {
    if (isLastStep) {
      await onCreate()
    } else {
      nextStep()
    }
  }

  const handleBack = () => {
    prevStep()
  }

  const onOptionClick = async () => {
    if (draftId) {
      // update
    } else {
      await onCreate(true)
    }
  }

  useEffect(() => {
    if (currentStep === 0) {
      setNextDisabled(safeNote.recipients.length === 0)

      return
    }

    const noTermsSelected =
      !safeNote.mfn &&
      !safeNote.valuationCapActive &&
      !safeNote.discountRateActive

    if (currentStep === 1) {
      setNextDisabled(noTermsSelected)
      return
    }

    if (currentStep === 2) {
      setNextDisabled(
        noTermsSelected ||
          !safeNote.signatures.senderSignature ||
          !safeNote.signatures.senderSignatureName
      )
      return
    }

    setNextDisabled(false)
  }, [safeNote, currentStep])

  useEffect(() => {
    if (me?.id && !me?.emailVerified) {
      navigate.toDashboard()
      showWarning('You cannot create safe until verify your email!')
    }
  }, [])

  useEffect(() => {
    if (!me?.id) return

    const permission = getPermission(me?.id, currentCompany)

    if (permission !== TeamMemberPermission.CREATE && !draftId) {
      navigate.toDashboard()
      showWarning(
        "You don't have permission to create SAFE note from this company!"
      )
    }
  }, [currentCompany, me?.id, draftId])

  useEffect(() => {
    if (me?.fullName) {
      const signatures = cloneDeep(safeNote.signatures)

      if (!signatures.senderSignatureName) {
        signatures.senderSignatureName = me.fullName
      }

      setSafeNote({ signatures })
    }
  }, [me?.fullName])

  useEffect(() => {
    if (draftId && !safeNote.id) {
      fetchDraftSafe()
    }
  }, [draftId])

  useBeforeunload(
    process.env.NODE_ENV === 'production'
      ? (event: BeforeUnloadEvent) => {
          event.preventDefault()
          // eslint-disable-next-line no-param-reassign
          event.returnValue = ''

          // Display the warning message
          return 'Are you sure you want to leave this page?'
        }
      : undefined
  )

  const Stepper = (
    <div className={styles.stepperWrapper}>
      <StepperNavigation
        type="small"
        activeStep={currentStep}
        className={styles.navigation}
        steps={createSafeSteps}
        itemTextType={TextTypes.BODY_DEFAULT}
        markPreviousAsCompleted={false}
        allowManuallyChangeStep
        onSelectStep={handleSelectStep}
      />
    </div>
  )

  const Navigation = (
    <Row items="stretch" gap={12}>
      {currentStep > 0 && (
        <div>
          <Button
            uppercase
            width="fit"
            appearance="secondary"
            disabled={loading}
            onClick={handleBack}
            className={styles.backButton}
          >
            <SharedIcons.Arrow size={20} color={Color.neutral300} />
          </Button>
        </div>
      )}

      <Button
        uppercase
        width="fit"
        onClick={handleNext}
        disabled={nextDisabled || loading}
        className="!tw-py-14 !tw-px-40"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? 'Creating...' : isLastStep ? 'Finish' : 'Next'}
        <SharedIcons.Arrow size={20} className="tw-ml-5" />
      </Button>

      <OptionsPopup options={options} onOptionClick={onOptionClick}>
        <div className={styles.optionsWrapper}>
          <SharedIcons.Dots color={Color.neutral400} />
        </div>
      </OptionsPopup>
    </Row>
  )

  return (
    <div className={clsx(styles.wrapper, isLastStep && styles.wide)}>
      <FullScreenLoading
        loading={loading || generatePdfLoading || fetchDraftLoading}
      />

      {visible && (
        <WarningModal
          id="exit-safe-process"
          title="You’re about to exit this page"
          description="All your unsaved SAFE progress will be lost."
          closeModal={closeModal}
          rightButtonText="Exit"
          rightButtonAction={() =>
            navigate.toDashboardCompany(companyId as string)
          }
        />
      )}

      {completed ? (
        <SafeNoteSummary
          recipients={safeNote.recipients}
          mfn={safeNote.mfn}
          discountRate={safeNote.discountRate}
          valuationCap={safeNote.valuationCap}
        />
      ) : (
        <>
          <HeaderBar
            withExit
            rightSide={Navigation}
            middleComponent={Stepper}
            onExit={() => openModal()}
          />
          <div className={styles.container}>
            <CreateSafeNoteContextProvider
              data={safeNote}
              currentStep={currentStep}
              updateData={setSafeNote}
              company={currentCompany}
              highlights={highlights}
              updateHighlights={setHighlights}
            >
              <div className={styles.containerInner}>
                <Outlet />
              </div>
            </CreateSafeNoteContextProvider>
          </div>
        </>
      )}
    </div>
  )
}

export default withStore(mapStateToProps)(CreateSafeNoteLayout)
