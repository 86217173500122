import { saveAs } from 'file-saver'
import { action, makeObservable, observable, runInAction } from 'mobx'

import { downloadPdfFile } from '@/common/api'
import { RootStoreType } from '@/common/store'
import StoreHandler from '@/common/store/StoreHandler'
import { ICompany } from '@/features/profile/types'
import { CreateSafeNoteDto, ISafeNote, SignSafeDto } from '@/features/safe-note'

import { SafeNoteRequests } from '../api/SafeNoteRequests'

class SafeNoteStore extends StoreHandler {
  @observable safeNoteList: ISafeNote[] = []

  @observable investmentCompanies: ICompany[] = []

  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
  }

  @action
  public create = this.execute<CreateSafeNoteDto>(
    async ({ data, options }) => {
      const response = await SafeNoteRequests.create(data)

      options?.onSuccess?.(response)
    },
    { loaderName: 'create', manualOnSuccess: true }
  )

  @action
  public fetchAll = this.execute<{
    entrepreneurCompanyId?: string
    angelCompanyId?: string
    query?: any
  }>(async ({ data }) => {
    const response = await SafeNoteRequests.fetchAll(
      data.entrepreneurCompanyId,
      data.angelCompanyId,
      data.query
    )
    if (response) {
      runInAction(() => {
        this.safeNoteList = response
      })
    }
  }, 'fetchAll')

  @action
  public fetchCompanySenders = this.execute<{
    angelCompanyId: string
  }>(async ({ data }) => {
    const result = await SafeNoteRequests.fetchCompaniesSenders(
      data.angelCompanyId
    )

    runInAction(() => {
      if (!!result) {
        this.investmentCompanies = result
      } else {
        this.investmentCompanies = []
      }
    })
  }, 'fetchCompanySenders')

  @action
  public downloadZip = this.execute<{
    companyId: string
    safeNoteIds: string[]
  }>(
    async ({ data, options }) => {
      const response: any = await SafeNoteRequests.downloadZip(
        data.companyId,
        data.safeNoteIds
      )

      const blob = new Blob([response], { type: 'application/zip' })

      saveAs(blob, 'downloadedFile.zip')

      options?.onSuccess?.()
    },
    { loaderName: 'downloadZip', manualOnSuccess: true }
  )

  @action
  public fetchPendingSafes = this.execute(
    async (): Promise<ISafeNote[]> => SafeNoteRequests.fetchPendingSafes(),
    'fetchPendingSafes'
  )

  @action
  public acceptSafeNote = this.execute<{ id: string; companyId: string }>(
    async ({ data }): Promise<boolean> =>
      SafeNoteRequests.acceptSafeNote(data.id, data.companyId),
    'acceptSafeNote'
  )

  @action
  public fetchOne = this.execute<{ id: string }>(
    async ({ data }) => SafeNoteRequests.fetchOne(data.id),
    'fetchOne'
  )

  @action
  public fetchFee = this.execute<{ id: string }>(
    async ({ data }) => SafeNoteRequests.fetchFee(data.id),
    'fetchFee'
  )

  @action
  public fetchWithToken = this.execute<{ token: string }>(
    async ({ data }) => SafeNoteRequests.fetchWithToken(data.token),
    'fetchWithToken'
  )

  @action
  public delete = this.execute<{ id: string; message?: string }>(
    async ({ data }) => {
      await SafeNoteRequests.delete(data.id, { message: data.message })
      runInAction(() => {
        this.safeNoteList = this.safeNoteList.filter((p) => p.id !== data.id)
      })
    },
    'delete'
  )

  @action
  public sign = this.execute<{ id: string } & SignSafeDto>(
    async ({ data: { id, ...data } }) => {
      await SafeNoteRequests.sign(id, data)
    },
    'sign'
  )

  @action
  public notifyToSign = this.execute<{ id: string; reminderMessage?: string }>(
    async ({ data: { id, reminderMessage } }) => {
      await SafeNoteRequests.notifyToSign(id, reminderMessage)
    },
    'notifyToSign'
  )

  @action
  public generatePdf = this.execute<{ html: string; fileName?: string }>(
    async ({ data: { html, fileName } }) => {
      const blob: any = await SafeNoteRequests.generatePdf(html)
      downloadPdfFile(blob, fileName || 'Unnamed_SafeNote')
    },
    'generatePdf'
  )
}

export default SafeNoteStore
